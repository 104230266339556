	.react-datepicker-wrapper {
		width: 100%;
		display: block;
		
	}
	.react-datepicker-popper{
		z-index: 9999;
	}

	.react-datepicker, .react-datepicker__header{
		border-radius: 30px!important;
	}
