@tailwind base;

h1 {
    @apply text-2xl;
    @apply font-semibold;
    @apply leading-relaxed;
}
h2 {
    @apply text-xl;
    @apply font-semibold;
    @apply leading-relaxed;
}
h3 {
    @apply text-lg;
    @apply font-semibold;
    @apply leading-relaxed;
  }
p {
    @apply mb-2;
    @apply text-gray-900;
}  

.cms ul {
    list-style: disc;
    list-style-position: inside;
}

html {
    scroll-behavior: smooth;
}

@tailwind components;

@tailwind utilities;