.vertical-border{
  background: linear-gradient(#B7D1D8, #B7D1D8) no-repeat center/1px 100%;
}

/* Range Input Styles */
/* Remove browser defaults to start with */
.carousel::-webkit-scrollbar {
  width: 0px;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}

input[type=range] {
  -webkit-appearance: none; width: 100%; background: #B7D1D8;height:2px
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
}

input[type=range]:focus {
  outline: none;
}

input[type=range]::-ms-track {
  width: 100%; cursor: pointer; 
  background: transparent; 
  border-color: transparent; color: transparent;
}

/* Special styling for WebKit/Blink */
input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: 4px solid #61B516;
  height: 18px;
  width: 18px;
  border-radius: 9px;
  background: #ffffff;
  cursor: pointer;
  
}

/* All the same stuff for Firefox */
input[type=range]::-moz-range-thumb {
  border: 4px solid #61B516;
  height: 18px;
  width: 18px;
  border-radius: 9px;
  background: #ffffff;
  cursor: pointer;
}

/* All the same stuff for IE */
input[type=range]::-ms-thumb {
  border: 4px solid #61B516;
  height: 18px;
  width: 18px;
  border-radius: 9px;
  background: #ffffff;
  cursor: pointer;
}

/* Multi Select Component - 3rd party override :( due to react-multi-select-component dependency installed by previous dev */

.amenities-filter .dropdown-container{
  border:0px
}

.amenities-filter .dropdown-heading-dropdown-arrow{
  display:none;
}

.amenities-filter .dropdown-heading-value .gray{
  font-size: .875rem;
  color:#8caeb9
}

.amenities-filter .dropdown-heading-value span{
  font-size: .875rem;
  color:#236477
}

.amenities-filter .dropdown-heading{
  height:3rem;
  padding-left:3rem;
  width:100%;
  background: #F4F9FA;
  margin-bottom: 0.5rem;
  outline: none;
  border: 1px #B7D1D8 solid;
  border-radius: 4px;
}



/* Date Picker */

.react-datepicker{
  font-family: Poppins, Helvetica, Arial, sans-serif;
  font-size: 15px;
  width:100%;
  border: 1px solid #B7D1D8;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  border-radius: 0.25rem;
  
}

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header{
  font-weight:600;
  color:#082B4D
}

.react-datepicker__day.react-datepicker__day--keyboard-selected.react-datepicker__day--today{
  background: #236477;
  color:#fff;
}

.react-datepicker__day--disabled.react-datepicker__day--range-start.react-datepicker__day--in-range{
  color:#fff!important
}

.react-datepicker__day--today{
  background:#236477;
  border-radius: 9999px;
  color:#fff!important
}

.react-datepicker__day-name{
  color:#236477;
  font-weight:700;
}

.react-datepicker__day--today, .react-datepicker__month-text--today, .react-datepicker__quarter-text--today{
  font-weight:400;
}

.react-datepicker__day.react-datepicker__day--keyboard-selected{
  background: #61B516;
  border-radius: 9999px;
}

.react-datepicker__day.react-datepicker__day--selected{
  background: #61B516;
  border-radius: 9999px;
  color:#fff
}
.react-datepicker__day--keyboard-selected {
  color: #ffffff!important
}


.react-datepicker__day.react-datepicker__day--in-range{
  background: #61B516;
  border-radius: 9999px;
  color:#fff
}

.react-datepicker__day.react-datepicker__day--in-selecting-range:hover{
  background: #92DB51;
  border-radius: 9999px;
  color: #ffffff;
}

.react-datepicker__day.react-datepicker__day--in-selecting-range{
  background: #92DB51;
  border-radius: 9999px;
  color: #ffffff;
}

.react-datepicker__day:hover{
  border-radius: 99999px;
}

.react-datepicker-popper{
  width:100%;
  min-width:250px;
  max-width: 300px;
  /*transform: translate3d(0px, 38px, 0px)!important;*/
}

.react-datepicker__header{
  background:#fff;
  border:none;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
}

.react-datepicker__navigation--next{
  border-left-color: #236477
}

.react-datepicker__navigation--previous{
  border-right-color:#236477
}

.react-datepicker__navigation{
  top:20px;  
}

.react-datepicker__day-name{
  margin:0; 
  padding-top:1rem;
}
.react-datepicker__month-container{
  width:100%;
  padding-bottom:1rem;
}
.react-datepicker__month{
  margin:0;
  padding-top:0.8rem;
  padding-left: 1rem;
  padding-right: 1rem;
}


.react-datepicker__week, .react-datepicker__day-names {
  display:flex;
  justify-content: space-between;
}

.react-datepicker__week{
  margin:2px 0px;
}

.react-datepicker__day, .react-datepicker__time-name{
  margin:0;
 
}


.react-datepicker__day--disabled, .react-datepicker__month-text--disabled, .react-datepicker__quarter-text--disabled{
  color:#B7D1D8!important
}

.react-datepicker__day{
  color:#082B4D
}
  
.react-datepicker-popper[data-placement^="bottom"]{
  margin-top:-8px;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle{
  border-bottom-color: #fff;
}

/* Google Maps Autocomplete */
.pac-container{
  background: #fff!important;
  border: 1px solid #B7D1D8;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  border-radius: 0.25rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.pac-item{
  color:#082B4D;
  border:0px;
  font-size: 16px;
  font-weight:400;
  font-family: Poppins, Helvetica, Arial, sans-serif;
  cursor: pointer;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.pac-item .pac-matched, .pac-item .pac-item-query{
  font-size: 16px;
  font-weight:400;
  font-family: Poppins, Helvetica, Arial, sans-serif;
}

.pac-icon.pac-icon-marker{
  display:none
}

/* Removing the annoying focus rings for now */

input:focus, button:focus {
  outline:none
}

button,
textarea,
input,
select,
img,
div,
a{
 -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
 -webkit-tap-highlight-color: transparent;

}

.opacity-gradient{
  background: linear-gradient(1turn,#0000,transparent 95%);
  background: -webkit-linear-gradient(bottom,hsla(0,0%,100%,0),#00000000 95%);
}
